/* .overview-graph-container {
  background: #ffffff;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 24px;
  max-width: 72rem;
} */

.drivers {
  flex: 1;
}
.statistics {
  flex: 1;
}

.separator {
  border: 1px solid #0000001a;
}
.data-row {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;

  letter-spacing: 0.2px;

  color: #000000cc;
}

.data-row > div {
  display: flex;
  justify-content: space-between;
}

.data-row-percentage {
  margin-left: 1em;
}

ol {
  list-style-type: decimal;
  list-style-position: outside;
  padding-left: 1rem;
}

li {
  display: list-item;
}

.popover {
  background-color: #ffffff;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  margin: 20px 20px 20px 0px;
  border-radius: 8px;
  box-shadow: 4px 4px 15px 3px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 4px 4px 15px 3px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 4px 4px 15px 3px rgba(0, 0, 0, 0.05);
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.2px;
  padding: 8px 16px 8px 16px;
}

.popover ul {
  list-style-type: none;
  margin: 8px 8px 8px -24px;
}

.popover ul li {
  margin-top: 4px;
  opacity: 0.8;
}
