.language-changer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.language-changer button {
  /* Reset browser default button styles */
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  appearance: button;
  box-shadow: 0;
  font-family: inherit;
  font-size: 100%;
  text-transform: none;

  /* Actual styles */
  width: 50%;
  color: #363740;
  padding: 1rem;
  cursor: pointer;
}

.language-changer button:hover {
  background-color: #3637400a;
}

.language-changer button.active {
  background-color: #3637400a;
}
