@import-normalize;

* {
  font-family: "Mulish", sans-serif;
}

a {
  color: #3751ff;
  cursor: pointer;
}

a:hover {
  color: #3751ff;
  text-decoration: underline;
}
